import ReactPlayer from 'react-player';
import longArrowRight from '../assets/images/long-arrow-right.svg';

import {
  Box,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export const MyxelliaVideoFullScreen = ({url}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Flex
        mt="42px"
        alignItems={'center'}
        justify={'flex-start'}
        gap="10px"
        cursor={'pointer'}
        onClick={onOpen}
      >
        <Text
          _hover={{textDecoration: 'underline'}}
          fontSize={{base: '12px', md: '16px'}}
          fontWeight={500}
          lineHeight={'30px'}
          color={'#4545FE'}
          fontFamily={'euclid-medium'}
        >
          Watch in full-screen
        </Text>
        <Image src={longArrowRight} w="23.593px" h="auto" />
      </Flex>
      <Modal onClose={onClose} size={'full'} isOpen={isOpen} isCentered bg="transparent">
        <ModalOverlay />
        <ModalContent
          width={'85vw !important'}
          maxH={'max-content !important'}
          bg="transparent"
          boxShadow={'none'}
          style={{aspectRatio: '16 / 9'}}
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
        >
          <Center
            style={{
              aspectRatio: '16 / 9',
            }}
          >
            <ReactPlayer
              url={url}
              style={{
                aspectRatio: '16 / 9',
                width: '100%',
                backgroundColor: '#000000',
                margin: 'auto',
              }}
              width={'100%'}
              height={'100%'}
              playing={true}
              loop
              controls
            />
          </Center>
          {/* <video
            style={{
              aspectRatio: '16 / 9',
              // width: '80vw',
              width: '100%',
              backgroundColor: '#000000',
              margin: 'auto',
            }}
            autoPlay={'autoplay'}
            preLoad="auto"
            loop
            controls
          >
            <source src={url} type="video/mp4" />
          </video> */}
        </ModalContent>
      </Modal>
    </>
  );
};
