import React, {useEffect, useRef} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import {Box, Text, Select, Flex, Image, HStack, Center, SimpleGrid} from '@chakra-ui/react';
// import buildingBg from '../../assets/images/myxellia.gif';
import buildingBg from '../../assets/images/myxellia-no-loop.gif';
import {Button} from '../../components/Buttons';
import longArrowRight from '../../assets/images/long-arrow-right.svg';
import smile from '../../assets/images/smile.svg';
import smarter from '../../assets/images/smarter.svg';
import receipt from '../../assets/images/receipt.svg';
import track from '../../assets/images/track.svg';
import landingMap from '../../assets/images/landing-map.png';
import numb1 from '../../assets/images/69B+.svg';
import numb2 from '../../assets/images/20+.svg';
import numb3 from '../../assets/images/10X.svg';
import numb4 from '../../assets/images/99.9.svg';
import whyCant from '../../assets/images/why-cant.png';
import smartPayment from '../../assets/images/smart-payment.png';
import research from '../../assets/images/research-img.png';
import monitor from '../../assets/images/monitor.png';
import billboard from '../../assets/images/veerge-billboard.png';
import Footer from '../../components/Footer/Footer';
import {Link} from 'react-router-dom';
import arrowDown from '../../assets/images/arrow-down.svg';
import caretDown from '../../assets/images/caret-down.svg';
import allEmcompass1 from '../../assets/images/all-emcompass1.png';
import allEmcompass2 from '../../assets/images/all-emcompass2.png';
import allEmcompass3 from '../../assets/images/all-emcompass3.png';
// import myxelliaVideo from '../../assets/myxellia-video.mp4';
import people from '../../assets/images/people.png';
import peopleTop from '../../assets/images/people-top.png';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import fraction from '../../assets/images/fraction.png';
import small1 from '../../assets/images/small1.png';
import small2 from '../../assets/images/small2.png';
import small3 from '../../assets/images/small3.png';
import small4 from '../../assets/images/small4.png';
import small5 from '../../assets/images/small5.png';
import Carousel from 'react-elastic-carousel';
import {MyxelliaVideoFullScreen} from '../../components/MyxelliaVideoFullScreen';
import ReactPlayer from 'react-player';

const Home = () => {
  const descData = [
    {
      title: 'Connect in a whole new way',
      description: `Build an application that covers the complete journey of homebuyers without writing a line of code. Myxellia is a cloud based infrastructure of interconnected apps and services for real estate development`,
      imageg: smile,
    },
    {
      title: 'Track Payments',
      description: `Create a user-friendly instant application for seamless payment management. 
      Enable subscribers to effortlessly monitor payments, receiving gentle reminders through timely notifications for payment plans.`,
      imageg: receipt,
    },
    {
      title: 'CRM',
      description: `Attain a unified, comprehensive customer view. By understanding your customers' interests and concerns, 
      you can enhance their experience with personalized services, thus improving their perception of your business.`,
      imageg: track,
    },
    {
      title: 'Sell smarter, not harder!',
      description: `Unleash a borderless, limitless, and powerful solution that adapts to evolving customer expectations. 
      Equipped with all the essentials to fuel your business, align your teams, and focus your business on what matters most - the customer.`,
      imageg: smarter,
    },
  ];

  const numbers = [
    {
      text: 'Transactions Processed',
      image: numb1,
      end: 69,
      prefix: 'B+',
    },
    {
      text: 'Apps & Services',
      image: numb2,
      end: 20,
      prefix: '+',
    },
    {
      text: 'Customer Retention',
      image: numb3,
      end: 10,
      prefix: 'X',
    },
    {
      text: 'Reliability',
      image: numb4,
      end: 99.9,
      prefix: '%',
      isDecimal: true,
    },
  ];

  const researchData = [
    {
      tag: 'Research',
      title: 'Myxellia’s fractional ownership',
      desc: 'If fractional ownership is such a game-changer, why are so many Proptech startups still struggling with it?',
      image: research,
      href: 'https://veerge-support.myxellia.io/blog/fractional_ownership',
    },
    {
      tag: 'Research',
      title: 'Why Veerge instead of building',
      desc: 'An Infrastructure that empowers you to adapt to changing customer expectations with everything you need to power your business.',
      image: whyCant,
      href: 'https://veerge-support.myxellia.io/blog/why_veerge',
    },
    {
      tag: 'What’s new?',
      title: 'Smart payment plan',
      desc: "We've automated every aspect of the home-ownership process, but now it's time for a transformative re-engineering.",
      image: smartPayment,
      href: 'https://veerge-support.myxellia.io/blog/smart_payment',
    },
  ];

  const supportedCountries = [
    'Nigeria',
    'Ghana',
    'Kenya',
    'Rwanda',
    'South Africa',
    'Tanzania',
    'Ethiopia',
    'Egypt',
    'Morocco',
    'Ivory Coast',
    'Mauritius',
    'UAE',
    'Qatar',
    'Saudi Arabia',
    'Bahrain',
    'Qatar',
    'Oman',
    'Jordan',
  ];

  const allEmcompass = [allEmcompass1, allEmcompass2, allEmcompass3];

  const vidRef = useRef();

  const myxellia_video_url =
    'https://res.cloudinary.com/dduiws3yh/video/upload/v1709505542/myxellia-full-video_e8inb9.mp4';

  useEffect(() => {
    vidRef.current.play();
  }, []);

  return (
    <Box fontFamily={'euclid'} position={'relative'} w="full">
      <Navbar />
      <Box
        bg="#F2F2F2"
        bgImage={{base: 'unset', md: buildingBg}}
        w={{base: '100%', md: '85%'}}
        h="full"
        minH={'630px'}
        ml={'auto'}
        bgPosition={'center'}
        bgSize={'cover'}
        pt={{base: '70px', md: '70px'}}
      >
        <Flex
          h="fit-content"
          w={{base: '100%', md: '570px'}}
          bg={{base: 'inherit', md: '#fff'}}
          align={'stretch'}
          direction={'column'}
          justify={'flex-start'}
          ml={{base: 'auto', md: '-175px'}}
          mr={{base: 'auto', md: 'unset'}}
          p={{base: '24px', md: '40px 21px 41px 76px'}}
        >
          <Text
            fontFamily={'syne_semibold'}
            fontSize={{base: '24px', md: '40px'}}
            fontWeight={600}
            color="#011B33"
          >
            The Internet of Real Estate
          </Text>
          <Text
            mt={{base: '8px', md: '20px'}}
            fontSize={{base: '12px', md: '18px'}}
            fontWeight={400}
            lineHeight={{base: '15.471px', md: '30px'}}
            color="#011B33"
            fontFamily={'euclid'}
          >
            An ever-evolving tech of interconnected apps & services, built to make real estate work
            better for all.
          </Text>
          <Image
            mt="0px"
            src={buildingBg}
            objectFit={'cover'}
            objectPosition={'-10px'}
            w="100%"
            h="350px"
            display={{base: 'block', md: 'none'}}
          />
          <Flex
            mt={{base: '2px', md: '24px'}}
            px={{base: '10px', md: '18px'}}
            h="56px"
            w={{base: '100%', md: '469px'}}
            borderRadius={'4px'}
            align={'center'}
            justify={'center'}
            gap={{base: '2px', md: '4px'}}
            border="1.5px solid #1919D8"
          >
            <Image src={arrowDown} />
            <Select
              icon={
                <Image
                  src={caretDown}
                  w={{base: '10px', md: '15px'}}
                  h={{base: 'auto', md: '10px'}}
                />
              }
              border="none"
              px="0"
              py="0"
              _focusVisible={{border: 'none'}}
            >
              {supportedCountries.map(country => (
                <option>{country}</option>
              ))}
            </Select>
          </Flex>
          <Text
            fontSize={{base: '10px', md: '12px'}}
            fontWeight={400}
            lineHeight={{base: '21.876px', md: '30px'}}
            mt="16px"
            letterSpacing={'-0.2px'}
          >
            Operating in over 10+ countries.
          </Text>
          <Link to="/veerge">
            <Button
              borderRadius="0"
              w={{base: 'full', md: '466px'}}
              h={{base: '40px', md: '48px'}}
              bg="#4545FE"
              mt="11px"
              _hover={{bg: '#4545FE'}}
              _active={{bg: '#4545FE'}}
            >
              <Text fontSize={'14px'} fontWeight={500} lineHeight={'30px'} color={'#fff'}>
                Get Started
              </Text>
            </Button>
          </Link>
        </Flex>
      </Box>

      <Box w={{base: '90%', md: '85%'}} mx="auto">
        <Flex w="full" alignItems={'center'} justify={'space-between'} mt="47px" mb="62px">
          <Text
            lineHeight={{base: 'normal', md: '44px'}}
            fontSize={{base: '14px', md: '24px'}}
            fontWeight={{base: 600, md: 500}}
            maxW={'512px'}
            fontFamily={'euclid-medium'}
          >
            Build an{' '}
            <Text as="span" color={'#4545FE'}>
              end-to-end
            </Text>{' '}
            application in minutes without writing a single line of code
          </Text>
          <Box display={{base: 'none', md: 'block'}}>
            <a
              href="https://veerge-support.myxellia.io/users_experience"
              target="_blank"
              rel="noreferrer"
            >
              <Flex alignItems={'center'} justify={'center'} gap="10px" cursor={'pointer'}>
                <Text
                  _hover={{textDecoration: 'underline'}}
                  fontSize={{base: '12px', md: '16px'}}
                  fontWeight={500}
                  lineHeight={'30px'}
                  color={'#4545FE'}
                  fontFamily={'euclid-medium'}
                >
                  View all solutions
                </Text>
                <Image src={longArrowRight} w="23.593px" h="auto" />
              </Flex>
            </a>
          </Box>
        </Flex>

        <Flex
          direction={{base: 'column', md: 'row'}}
          align={{base: 'center', md: 'flex-start'}}
          justify={'center'}
          gap="13px"
        >
          {descData.map(desc => (
            <Box
              border="1px solid #E4E4E4"
              w={{base: 'full', md: '320px'}}
              h={{base: '285px', md: '280px'}}
            >
              <Box h="16px" w="full" bg="#4545FE" />
              <Flex
                direction={'column'}
                align={'stretch'}
                pr={{base: '28px', md: '24px'}}
                pl={{base: '25px', md: '15px'}}
                pb="11px"
                justify={'space-between'}
                h="calc(100% - 16px)"
              >
                <Box w="full">
                  <Text
                    fontSize={{base: '14', md: '16px'}}
                    fontWeight={{base: 400, md: 500}}
                    color={'#101828'}
                    my="10px"
                    fontFamily={'euclid-semibold'}
                    noOfLines={1}
                  >
                    {desc.title}
                  </Text>
                  <Text
                    maxW={{base: 'full', md: '247px'}}
                    maxH="140px"
                    mt="6px"
                    overflowY={'hidden'}
                    fontSize={'13px'}
                    fontWeight={400}
                    lineHeight={'normal'}
                    color={'#606060'}
                    fontFamily={'euclid'}
                  >
                    {desc.description}
                  </Text>
                </Box>
                <Center
                  ml="auto"
                  border={'1px solid #606060'}
                  h="34px"
                  w="50px"
                  boxShadow={'0px 3.692px 3.692px -1.846px rgba(16, 24, 40, 0.03)'}
                >
                  <Image src={desc.imageg} />
                </Center>
              </Flex>
            </Box>
          ))}
        </Flex>

        <Box mt="19px" display={{base: 'block', md: 'none'}}>
          <a
            href="https://veerge-support.myxellia.io/users_experience"
            target="_blank"
            rel="noreferrer"
          >
            <Flex alignItems={'center'} justify={'flex-start'} gap="10px" cursor={'pointer'}>
              <Text
                _hover={{textDecoration: 'underline'}}
                fontSize={{base: '12px', md: '16px'}}
                fontWeight={500}
                lineHeight={'30px'}
                color={'#4545FE'}
                fontFamily={'euclid-medium'}
              >
                View all solutions
              </Text>
              <Image src={longArrowRight} w="23.593px" h="auto" />
            </Flex>
          </a>
        </Box>
      </Box>

      <Box w="100%" mt={{base: '40px', md: '100px'}} bg="#F7F7F7" py={{base: '18px', md: '45px'}}>
        <Flex
          w={{base: '90%', md: '85%'}}
          mx="auto"
          align={'center'}
          direction={{base: 'column', md: 'row'}}
          justify={'space-between'}
          gap={{base: '10px', md: '80px'}}
        >
          <Text
            mb="18px"
            alignSelf={'flex-start'}
            display={{base: 'block', md: 'none'}}
            fontSize={{base: '14px', md: '20px'}}
            fontWeight={400}
            lineHeight={{base: '8.125px', md: '30px'}}
            color={'#606060'}
          >
            Technology Infrastructure
          </Text>
          <Image w={{base: '100%', md: '54%'}} src={landingMap} />
          <Box>
            <Text
              display={{base: 'none', md: 'block'}}
              fontSize={'20px'}
              fontWeight={400}
              lineHeight={'30px'}
              color={'#606060'}
            >
              Technology Infrastructure
            </Text>
            <Text
              mt="21px"
              maxW="540px"
              fontSize={{base: '14px', md: '26px'}}
              fontWeight={500}
              lineHeight={{base: 'normal', md: '30px'}}
              color={'#011B33'}
              fontFamily={'euclid-medium'}
            >
              Become a global proptech business from day one
            </Text>
            <Text
              mt={{base: '16px', md: '41px'}}
              maxW={'468px'}
              fontSize={{base: '14px', md: '18px'}}
              fontWeight={400}
              lineHeight={'normal'}
              color={'#3D3D3D'}
            >
              Give potential buyers around the world access to your business by letting Veerge
              manage the complexity technology, so you can easily onboard and accept payments from
              subscribers in 50+ countries and 135+ currencies without needing to establish local
              entities.
            </Text>
            <Link to={'/schedule'}>
              <Flex mt="20px" alignItems={'center'} justify={'flex-start'} gap="10px">
                <Text
                  _hover={{textDecoration: 'underline'}}
                  fontSize={{base: '12px', md: '16px'}}
                  fontWeight={500}
                  lineHeight={'30px'}
                  color={'#4545FE'}
                  fontFamily={'euclid-medium'}
                >
                  Schedule demo
                </Text>
                <Image src={longArrowRight} w="23.593px" h="auto" />
              </Flex>
            </Link>
          </Box>
        </Flex>
      </Box>

      <Box w="85%" mx="auto" my={{base: '20px', md: '50px'}}>
        <Text
          fontSize={{base: '12px', md: '20px'}}
          fontWeight={400}
          lineHeight={{base: '8.125px', md: '30px'}}
          color={'#606060'}
        >
          Infrastructure
        </Text>
        <Text
          mt="11px"
          maxW={'540px'}
          fontSize={{base: '14px', md: '26px'}}
          fontWeight={500}
          lineHeight={{base: '11.917px', md: '30px'}}
          color={'#011B33'}
          fontFamily={'euclid-medium'}
        >
          A Technology built for growth
        </Text>
        <HStack
          display={{base: 'none', md: 'flex'}}
          mt="37px"
          w="full"
          justify={'space-between'}
          align={'center'}
        >
          {numbers.map(numb => (
            <Center
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'space-between'}
              h="166px"
            >
              <Box />
              <CountUp
                duration={2.75}
                separator=" "
                decimal={numb.isDecimal ? '.' : null}
                decimals={numb.isDecimal ? 1 : null}
                suffix={numb.prefix}
                end={numb.end}
                redraw={true}
              >
                {({countUpRef, start}) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span
                      style={{
                        fontFamily: 'syne_bold',
                        color: '#4545FE',
                        fontSize: '100px',
                        fontWeight: '700',
                        lineHeight: '30px',
                      }}
                      ref={countUpRef}
                    />
                  </VisibilitySensor>
                )}
              </CountUp>
              <Text
                w="full"
                pl="10px"
                fontSize={'20px'}
                fontWeight={400}
                lineHeight={'44px'}
                color="#747474"
              >
                {numb.text}
              </Text>
            </Center>
          ))}
        </HStack>

        <SimpleGrid
          columns={2}
          rowGap={'60px'}
          columnGap={'10px'}
          display={{base: 'grid', md: 'none'}}
          mt="37px"
          w="full"
          justify={'space-between'}
          align={'center'}
        >
          {numbers.map(numb => (
            <Center
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'space-between'}
              h={{base: '95px', md: '166px'}}
            >
              <Box />
              <CountUp
                duration={2.75}
                separator=""
                decimal={numb.isDecimal ? '.' : null}
                decimals={numb.isDecimal ? 1 : null}
                suffix={numb.prefix}
                end={numb.end}
                redraw={true}
              >
                {({countUpRef, start}) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span
                      style={{
                        fontFamily: 'syne_bold',
                        color: '#4545FE',
                        fontSize: '40px',
                        fontWeight: '700',
                        lineHeight: '130%',
                      }}
                      ref={countUpRef}
                    />
                  </VisibilitySensor>
                )}
              </CountUp>
              <Text
                w="full"
                textAlign={'center'}
                fontSize={{base: '14px', md: '20px'}}
                fontWeight={400}
                lineHeight={{base: 'normal', md: '44px'}}
                color="#747474"
              >
                {numb.text}
              </Text>
            </Center>
          ))}
        </SimpleGrid>
      </Box>

      <Box
        bg="#F7F7F7"
        w="full"
        h="443px"
        pb={{base: '500px', md: 'unset'}}
        my={{base: 'unset', md: '200px'}}
      >
        <Flex
          display={{base: 'none', md: 'flex'}}
          mt="84px"
          w="85%"
          mx="auto"
          justify={'flex-start'}
          gap="72px"
          h="full"
          align={'center'}
        >
          <Box w="60%" position={'relative'} h="130%">
            <Image w="full" h="full" src={monitor} />
            <Box
              w="98%"
              h="80%"
              position={'absolute'}
              top="1.5%"
              left={'1%'}
              // zIndex={20}
              zIndex={10}
            >
              <video
                ref={vidRef}
                style={{
                  objectFit: 'cover',
                  borderRadius: '10px',
                  height: '100%',
                  zIndex: 10,
                }}
                width="100%"
                muted
                autoPlay={'autoplay'}
                preLoad="auto"
                loop
              >
                <source src={myxellia_video_url} type="video/mp4" />
              </video>
            </Box>
          </Box>
          <Box maxW="33%">
            <Text fontSize={'20px'} fontWeight={400} lineHeight={'30px'} color={'#606060'}>
              Solution
            </Text>
            <Text fontFamily={'euclid-medium'} fontSize={'30px'} fontWeight={500} color="#011B33">
              One platform, infinite possibilities
            </Text>
            <Text>
              Create a cutting-edge web application tailored to meet all your real estate needs,
              empowering you to bring your vision to life with a comprehensive suite of advanced
              functionalities.
            </Text>
            <MyxelliaVideoFullScreen url={myxellia_video_url} />
            {/* <a
              href={'/myxellia-full-video.mp4'}
              target='_blank'
              rel='noreferrer'
            >
              <Flex
                mt='42px'
                alignItems={'center'}
                justify={'flex-start'}
                gap='10px'
                cursor={'pointer'}
              >
                <Text
                  _hover={{ textDecoration: 'underline' }}
                  fontSize={{ base: '12px', md: '16px' }}
                  fontWeight={500}
                  lineHeight={'30px'}
                  color={'#4545FE'}
                  fontFamily={'euclid-medium'}
                >
                  Watch in full-screen
                </Text>
                <Image src={longArrowRight} w='23.593px' h='auto' />
              </Flex>
            </a> */}
          </Box>
        </Flex>
        <Box mb={{base: '16px', md: '0'}} px="24px" py="16px" display={{base: 'block', md: 'none'}}>
          <Text fontSize={'12px'} fontWeight={400} lineHeight={'8px'} color={'#606060'}>
            Solution
          </Text>
          <Text fontSize={'14px'} fontWeight={500} lineHeight={'11px'} color={'#101828'} mt="12px">
            One platform, infinte possibilities
          </Text>

          {/* <Box w="100%" position={'relative'} h="235.304px" mt="24px"> */}
          <Box
            w="100%"
            position={'relative'}
            // h="235.304px"
            mt="24px"
            style={{aspectRatio: '342 / 235'}}
          >
            <Image w="full" h="full" src={monitor} />
            <Box
              w="98%"
              h="80%"
              position={'absolute'}
              top="1.5%"
              left={'1%'}
              zIndex={10}
              borderRadius={'4px'}
              overflow={'hidden'}
            >
              {/* <video
                ref={vidRef}
                style={{
                  objectFit: 'cover',
                  borderRadius: '10px',
                  height: '100%',
                  zIndex: 10,
                }}
                width="100%"
                muted
                autoPlay={'autoplay'}
                preLoad="auto"
                loop
              >
                <source src={myxelliaVideo} type="video/mp4" />
              </video> */}
              <ReactPlayer
                url={myxellia_video_url}
                style={{
                  objectFit: 'cover',
                  borderRadius: '10px',
                  height: '100%',
                  zIndex: 10,
                }}
                width={'100%'}
                muted
                height={'100%'}
                playing={true}
                loop
              />
            </Box>
          </Box>

          <Text
            fontSize={'14px'}
            fontWeight={400}
            lineHeight={'normal'}
            color={'#3D3D3D'}
            mt="24px"
          >
            Create a cutting-edge web application tailored to meet all your real estate needs,
            empowering you to bring your vision to life with a comprehensive suite of advanced
            functionalities.
          </Text>

          <a href={myxellia_video_url} target="_blank" rel="noreferrer">
            <Flex
              mt="24px"
              alignItems={'center'}
              justify={'flex-start'}
              gap="10px"
              cursor={'pointer'}
            >
              <Text
                _hover={{textDecoration: 'underline'}}
                fontSize={{base: '12px', md: '16px'}}
                fontWeight={500}
                lineHeight={'14.444px'}
                color={'#4545FE'}
                fontFamily={'euclid-medium'}
              >
                Watch in full-screen
              </Text>
              <Image src={longArrowRight} w="23.593px" h="auto" />
            </Flex>
          </a>
        </Box>
      </Box>

      <Box w="85%" mx="auto" mt={{base: '28px', md: '134px'}}>
        <Flex
          direction={{base: 'column', md: 'row'}}
          w="full"
          alignItems={{base: 'flex-start', md: 'center'}}
          justify={'space-between'}
          mt={{base: '0', md: '47px'}}
          mb={{base: '16px', md: '62px'}}
        >
          <Text
            mb={{base: '24px', md: '0'}}
            lineHeight={{base: 'normal', md: '44px'}}
            fontSize={{base: '14px', md: '24px'}}
            fontWeight={{base: 600, md: 500}}
            maxW={'610px'}
            fontFamily={'euclid-medium'}
          >
            An all-encompassing,
            <Text as="span" color={'#4545FE'}>
              {' '}
              point of convergence{' '}
            </Text>{' '}
            where your offerings & buyers coexist seamlessly every day.
          </Text>
          <a
            href="https://veerge-support.myxellia.io/users_experience"
            target="_blank"
            rel="noreferrer"
          >
            <Flex alignItems={'center'} justify={'center'} gap="10px" cursor={'pointer'}>
              <Text
                _hover={{textDecoration: 'underline'}}
                fontSize={{base: '12px', md: '16px'}}
                fontWeight={500}
                lineHeight={'30px'}
                color={'#4545FE'}
                fontFamily={'euclid-medium'}
              >
                View all solutions
              </Text>
              <Image src={longArrowRight} w="23.593px" h="auto" />
            </Flex>
          </a>
        </Flex>

        <HStack
          display={{base: 'none', md: 'flex'}}
          spacing={'41px'}
          justify={'space-between'}
          align={'flex-start'}
        >
          {allEmcompass.map(emcompass => (
            <Box
              position={'relative'}
              p="0"
              m="0"
              cursor={'pointer'}
              overflow={'hidden'}
              maxW={'auto'}
              w="full"
            >
              <Image
                h="401px"
                transition="transform 0.3s ease-in-out"
                _hover={{transform: 'scale(1.2)', maxW: '100%'}}
                objectPosition={'center'}
                objectFit={'contain'}
                w="full"
                src={emcompass}
              />
            </Box>
          ))}
        </HStack>

        <Box display={{base: 'flex', md: 'none'}}>
          <Carousel
            pagination={true}
            showArrows={false}
            renderPagination={props => (
              <Center mt="20px" gap="4px">
                {allEmcompass.map((box, i) => (
                  <Box
                    bg={props.activePage === i ? '#000' : '#00000066'}
                    w="5px"
                    h="5px"
                    borderRadius={'full'}
                  />
                ))}
              </Center>
            )}
          >
            {allEmcompass.map(emcompass => (
              <Box
                position={'relative'}
                p="0"
                m="0"
                cursor={'pointer'}
                overflow={'hidden'}
                maxW={'auto'}
                w="full"
                h={{base: 'auto', md: '340px'}}
              >
                <Image
                  h="auto"
                  transition="transform 0.3s ease-in-out"
                  _hover={{transform: 'scale(1.2)', maxW: '100%'}}
                  objectPosition={'center'}
                  objectFit={'contain'}
                  w="full"
                  src={emcompass}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>

      <Box
        bg="#F7F7F7"
        w="full"
        h={{base: 'auto', md: '536px'}}
        mt={{base: '28px', md: '184px'}}
        pb={{base: '28px', md: 'unset'}}
      >
        <Flex
          gap={{base: '20px', md: 'unset'}}
          direction={{base: 'column', md: 'row'}}
          pt={{base: '28px', md: 'unset'}}
          mt={{base: '28px', md: '84px'}}
          w="85%"
          mx="auto"
          justify={'space-between'}
          h="full"
          align={'center'}
        >
          <Box w={{base: '100%', md: '55%'}} position={'relative'} h="auto">
            <Image
              w={{base: '30px', md: '60px'}}
              h={{base: '30px', md: '60px'}}
              top="20%"
              right="3%"
              position={'absolute'}
              borderRadius={'full'}
              src={small1}
            />
            <Image
              w={{base: '30px', md: '60px'}}
              h={{base: '30px', md: '60px'}}
              bottom="30%"
              right="5%"
              position={'absolute'}
              borderRadius={'full'}
              src={small2}
            />
            <Image
              w={{base: '30px', md: '60px'}}
              h={{base: '30px', md: '60px'}}
              top="50%"
              left="-3%"
              position={'absolute'}
              borderRadius={'full'}
              src={small3}
            />
            <Image
              w={{base: '30px', md: '60px'}}
              h={{base: '30px', md: '60px'}}
              bottom="10%"
              left="8%"
              position={'absolute'}
              borderRadius={'full'}
              src={small4}
            />
            <Image
              w={{base: '30px', md: '60px'}}
              h={{base: '30px', md: '60px'}}
              top="20%"
              left="9%"
              position={'absolute'}
              borderRadius={'full'}
              src={small5}
            />
            <Image
              w={{base: '180px', md: '362px'}}
              h={{base: '340px', md: '648.075px'}}
              mx={'auto'}
              my="auto"
              src={fraction}
            />
          </Box>
          <Box maxW={{base: '100%', md: '40%'}}>
            <Text
              display={{base: 'none', md: 'block'}}
              fontSize={'20px'}
              fontWeight={400}
              lineHeight={'30px'}
              color={'#606060'}
            >
              Technology Infrastructure
            </Text>
            <Text
              display={{base: 'none', md: 'block'}}
              fontFamily={'euclid-medium'}
              fontSize={'25px'}
              fontWeight={500}
              color="#011B33"
            >
              Develop an application capable of <br /> fractionalizing real estate assets.
            </Text>
            <Text
              mt="7px"
              fontSize={{base: '14px'}}
              fontWeight={{base: 400}}
              lineHeight={{base: 'normal'}}
            >
              Without writing a single line of code, real estate businesses can also seamlessly
              enter the realm of real estate fractionalization. This dynamic framework empowers the
              business to navigate complexities effortlessly, unlocking new avenues for growth and
              engagement
            </Text>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://veerge-support.myxellia.io/blog/fractional_ownership"
            >
              <Flex
                mt="22px"
                alignItems={'center'}
                justify={'flex-start'}
                gap="10px"
                cursor={'pointer'}
              >
                <Text
                  _hover={{textDecoration: 'underline'}}
                  fontSize={{base: '12px', md: '16px'}}
                  fontWeight={500}
                  lineHeight={'30px'}
                  color={'#4545FE'}
                  fontFamily={'euclid-medium'}
                >
                  Read more
                </Text>
                <Image src={longArrowRight} w="23.593px" h="auto" />
              </Flex>
            </a>
          </Box>
        </Flex>
      </Box>

      <Flex
        direction={{base: 'column-reverse', md: 'row'}}
        mt={{base: '28px', md: '184px'}}
        w="80%"
        mx="auto"
        justify={'space-between'}
        align={'center'}
      >
        <Box maxW={{base: '100%', md: '43%'}}>
          <Box display={{base: 'none', md: 'block'}}>
            <Text fontSize={'20px'} fontWeight={400} lineHeight={'30px'} color={'#606060'}>
              Technology Infrastructure
            </Text>
            <Text
              lineHeight={'50px'}
              fontFamily={'euclid-medium'}
              fontSize={'30px'}
              fontWeight={500}
              color="#011B33"
            >
              Co-ownership for Family & Friends
            </Text>
          </Box>
          <Text>
            Imagine the excitement of family and friends coming together to purchase a property
            through your application, seamlessly splitting the payment based on ownership
            percentage. With this innovative feature, your platform transforms the way people invest
            in real estate, fostering a sene shared ownership.
          </Text>
          <a
            href="https://veerge-support.myxellia.io/blog/fractional_ownership"
            target="_blank"
            rel="noreferrer"
          >
            <Flex
              mt="30px"
              alignItems={'center'}
              justify={'flex-start'}
              gap="10px"
              cursor={'pointer'}
            >
              <Text
                _hover={{textDecoration: 'underline'}}
                fontSize={{base: '12px', md: '16px'}}
                fontWeight={500}
                lineHeight={'30px'}
                color={'#4545FE'}
                fontFamily={'euclid-medium'}
              >
                What makes it different from fractional?
              </Text>
              <Image src={longArrowRight} w="23.593px" h="auto" />
            </Flex>
          </a>
        </Box>

        <Box w={{base: '100%', md: '45%'}} position={'relative'} mb={{base: '24px', md: '0'}}>
          <Image
            position={'absolute'}
            right={'-7%'}
            top="-15%"
            w={{base: '203px', md: '410px'}}
            h={{base: '902x', md: '180px'}}
            src={peopleTop}
          />
          <Image w="full" h={{base: '271px', md: '443px'}} src={people} />
        </Box>

        <Box display={{base: 'block', md: 'none'}} mb="70px" alignSelf={'flex-start'}>
          <Text fontSize={'12px'} fontWeight={400} lineHeight={'8.125px'} color={'#606060'}>
            Technology Infrastructure
          </Text>
          <Text
            mt="12px"
            lineHeight={'11.917px'}
            fontFamily={'euclid-medium'}
            fontSize={'14px'}
            fontWeight={500}
            color="#011B33"
          >
            Co-ownership for Family & Friends
          </Text>
        </Box>
      </Flex>

      <Flex
        direction={{base: 'column', md: 'row'}}
        w="85%"
        mx="auto"
        justify={'flex-start'}
        gap={{base: '28px', md: '72px'}}
        align={'center'}
        mt={{base: '40px', md: '107px'}}
      >
        <Box w={{base: '100%', md: '47%'}}>
          <Text
            fontSize={{base: '12px', md: '20px'}}
            fontWeight={400}
            lineHeight={{base: '8.125px', md: '30px'}}
            color={'#606060'}
          >
            Research
          </Text>
          <Text
            mt="18px"
            maxW={'540px'}
            fontSize={{base: '14px', md: '26px'}}
            fontWeight={500}
            lineHeight={{base: '11.917px', md: '30px'}}
            color={'#011B33'}
            fontFamily={'euclid-medium'}
          >
            Our ideas and insights
          </Text>
          <Image w="full" mt="20px" h={{base: '228.172px', md: '443px'}} src={billboard} />
        </Box>
        <Box maxW={{base: '100%', md: '40%'}}>
          <Text
            fontSize={{base: '12px', md: '20px'}}
            fontWeight={400}
            lineHeight={{base: '8.125px', md: '30px'}}
            color={'#606060'}
          >
            Research
          </Text>
          <Text
            fontFamily={'euclid-medium'}
            fontSize={{base: '16px', md: '30px'}}
            fontWeight={500}
            color="#011B33"
            lineHeight={{base: '29.109px', md: 'normal'}}
          >
            The New Era of Real Estate
          </Text>
          <Text fontWeight={{base: 400}} fontSize={{base: '14px'}}>
            An Infrastructure that empowers you to adapt to changing customer expectations with
            everything you need to power your business.
          </Text>
          <a
            href="https://veerge-support.myxellia.io/blog/new_era"
            target="_blank"
            rel="noreferrer"
          >
            <Flex
              mt="30px"
              alignItems={'center'}
              justify={'flex-start'}
              gap="10px"
              cursor={'pointer'}
            >
              <Text
                _hover={{textDecoration: 'underline'}}
                fontSize={{base: '12px', md: '16px'}}
                fontWeight={500}
                lineHeight={'30px'}
                color={'#4545FE'}
                fontFamily={'euclid-medium'}
              >
                Read more
              </Text>
              <Image src={longArrowRight} w="23.593px" h="auto" />
            </Flex>
          </a>
        </Box>
      </Flex>

      <Box
        mt={{base: '40px', md: '47px'}}
        mb={{base: '40px', md: '63px'}}
        borderBottom="1px solid #919191"
        w="85%"
        mx="auto"
      />

      <Box
        w="85%"
        px="20px"
        mx="auto"
        pb={{base: '48px', md: '100px'}}
        overflowX={{base: 'scroll', md: 'unset'}}
      >
        <HStack
          spacing={{base: '16px', md: '37px'}}
          align={'flex-start'}
          w={{base: '250%', md: '100%'}}
          justify={{base: 'unset', md: 'space-between'}}
        >
          {researchData.map(data => (
            <a href={data.href} target="_blank" rel="noreferrer">
              <Box w={{base: '260px', md: '393px'}}>
                <Image w="full" h={{base: '170px', md: '269px'}} src={data.image} />
                <Text
                  mt="15px"
                  fontSize={{base: '10px', md: '16px'}}
                  fontWeight={400}
                  color={'#606060'}
                >
                  {data.tag}
                </Text>
                <Text
                  mt="10px"
                  fontSize={{base: '16px', md: '24px'}}
                  fontWeight={600}
                  color={'#101828'}
                  fontFamily={'euclid'}
                >
                  {data.title}
                </Text>
                <Text
                  mt="10px"
                  fontSize={{base: '12px', md: '16px'}}
                  fontWeight={300}
                  // color={'#3D3D3D'}
                  color={'#606060'}
                >
                  {data.desc}
                </Text>
              </Box>
            </a>
          ))}
        </HStack>
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
