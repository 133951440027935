import React from 'react';
import {Image, Box, GridItem, VStack, Text, HStack, SimpleGrid} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import instagram from '../../assets/images/instagram.svg';
import linkedIn from '../../assets/images/linked-in.svg';
import mail from '../../assets/images/mail.svg';

const Footer = () => {
  return (
    <Box
      w="full"
      bg="#F7F7F7"
      pt={{base: '22px', md: '59px'}}
      pb={{base: '22px', md: '109px'}}
      fontFamily={'euclid'}
    >
      <SimpleGrid
        columnGap={{base: '20px', md: '30px'}}
        rowGap={{base: '50px', md: '15px'}}
        columns={{base: 2, md: 3}}
        w={{base: '90%', md: '75%'}}
        mx="auto"
        justify={'space-between'}
      >
        <GridItem colSpan={{base: '2', md: 1}}>
          <VStack spacing={'16px'} align={'stretch'}>
            <Box w="full">
              <Text w="fit-content" color="#0A142F" mb="8px">
                Help
              </Text>
            </Box>
            {/* <a target='_blank' rel='noreferrer' href='https://veerge-support.myxellia.io/'>
              <Box w='full'>
                <Text w='fit-content' color='#0A142F' opacity={0.65} className='hover-underline-animation'>Contact us</Text>
              </Box>
            </a> */}
            <a href="mailto:hello@myxellia.io">
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Contact us
                </Text>
              </Box>
            </a>
            <a target="_blank" rel="noreferrer" href="https://veerge.myxellia.io/dashboard">
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Explore Themes
                </Text>
              </Box>
            </a>
            <Link to="/schedule">
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Schedule Demo
                </Text>
              </Box>
            </Link>
            <Link to="/refer">
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Refer a property developer
                </Text>
              </Box>
            </Link>
            <Link to="/feedback">
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Give Feedback
                </Text>
              </Box>
            </Link>
            <Link to="/suggest_idea">
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Suggest an idea
                </Text>
              </Box>
            </Link>
          </VStack>
        </GridItem>
        <GridItem colSpan={{base: '2', md: 1}}>
          <VStack spacing={'16px'} align={'stretch'}>
            <Box w="full">
              <Text w="fit-content" color="#0A142F" mb="8px">
                Company
              </Text>
            </Box>
            <a
              href="https://veerge-support.myxellia.io/blog/why_veerge"
              target="_blank"
              rel="noreferrer"
            >
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Why us?
                </Text>
              </Box>
            </a>
            <a
              href="https://veerge-support.myxellia.io/blog/why_veerge#our_mission"
              target="_blank"
              rel="noreferrer"
            >
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Our mission
                </Text>
              </Box>
            </a>
            <a
              href="https://veerge-support.myxellia.io/blog/why_veerge#our_value"
              target="_blank"
              rel="noreferrer"
            >
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Our Values
                </Text>
              </Box>
            </a>
            <a
              href="https://veerge-support.myxellia.io/blog/why_veerge#our_culture"
              target="_blank"
              rel="noreferrer"
            >
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Our culture
                </Text>
              </Box>
            </a>
            <a target="_blank" rel="noreferrer" href="https://veerge-support.myxellia.io">
              <Box w="full">
                <Text
                  w="fit-content"
                  color="#0A142F"
                  opacity={0.65}
                  className="hover-underline-animation"
                >
                  Help Centre
                </Text>
              </Box>
            </a>
          </VStack>
        </GridItem>
        <GridItem colSpan={{base: '2', md: 1}}>
          <Box mt={{base: 'unset', md: '200px'}} fontWeight={500} fontSize={'16px'}>
            <HStack spacing={'15px'} align={'center'}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/veerge.io?igshid=MmVlMjlkMTBhMg=="
              >
                <Image w="24px" h="24px" src={instagram} />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/veerge/">
                <Image w="24px" h="24px" src={linkedIn} />
              </a>
              <a href="mailto:Hello@myxellia.io">
                <Image w="24px" h="24px" src={mail} />
              </a>
            </HStack>
            <HStack mt="14px" color={'#4545FE'} spacing={'30px'} fontFamily={'euclid-medium'}>
              <a target="_blank" rel="noreferrer" href={'https://veerge-support.myxellia.io/terms'}>
                <Text fontSize={'14px'} fontWeight={400}>
                  Terms of use{' '}
                </Text>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={'https://veerge-support.myxellia.io/privacy'}
              >
                <Text fontSize={'14px'} fontWeight={400}>
                  Privacy policy
                </Text>
              </a>
            </HStack>
            <Text mt="10px" fontSize={'14px'} fontWeight={400} color="#919191">
              Copyright © 2024 Myxellia Inc. All rights reserved.
            </Text>
          </Box>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default Footer;
