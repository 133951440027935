import {Flex, Image, Link, Text} from '@chakra-ui/react';
import success_icon from '../../assets/images/green_checkmark.gif';
import return_home_icon from '../../assets/images/return_home_icon.svg';

export const Success = ({type = 'default'}) => {
  const display_text = {
    default: {
      title: 'Thank You',
      text: 'We appreciate your contribution and we will reach out to you if we need more clarity.',
    },
    feedback: {
      title: 'Thank You',
      text: `Thank you so much for your insightful feedback! If there are any details we'd like to dive deeper into, rest assured, we'll be reaching out to you.`,
    },
    suggest_idea: {
      title: 'Thank You',
      text: `Thank you so much for your insightful feedback! If there are any details we'd like to dive deeper into, rest assured, we'll be reaching out to you.`,
    },
    refer: {
      title: 'Referral Succesful',
      text: 'Thank you for your valuable contribution. We will be in touch with you at the earliest opportunity.',
    },
    schedule_demo: {
      title: 'Request Succesful',
      text: 'Thank you for requesting a demo. We have succesfully received your request and will contact you promptly to arrange a convenient time',
    },
  };
  return (
    <Flex
      borderRadius={'20px'}
      border={{base: 'none', md: '1px solid #E4E4E4'}}
      bg="#f7f7f7"
      color="#191919"
      px={{base: '20px', md: '40px'}}
      w={{base: '100%', md: '80%'}}
      mx="auto"
      h="fit-content"
      py={{base: '5px', md: '20px'}}
      direction={'column'}
      align={'center'}
      justify={'center'}
      gap={'24px'}
      textAlign={'center'}
      fontFamily={'euclid'}
    >
      <Image src={success_icon} alt="Success" width={'88px'} style={{aspectRatio: '394 / 300'}} />
      <Text color="#191919" fontSize={'32px'} fontWeight={'600'}>
        {display_text[type].title}
      </Text>
      <Text color="#606060" fontSize={'14px'}>
        {display_text[type].text}
      </Text>
      <Link
        href="/"
        display={'flex'}
        alignItems="center"
        gap={'8px'}
        _hover={{textDecoration: 'none'}}
      >
        <Image src={return_home_icon} alt="Success" width={'36px'} height={'36px'} />
        <Text color={'#191919'} fontSize={'18px'}>
          Return to Homepage
        </Text>
      </Link>
    </Flex>
  );
};
