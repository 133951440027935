import {
  Box,
  Flex,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Image,
  Select,
  SimpleGrid,
  Text,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {Button} from '../../components/Buttons';
import nigerianFlag from '../../assets/images/nigeria-flag.png';
import FormWrapper from './wrapper';
import angleLeft from '../../assets/images/angle-left.svg';
import {useNavigate} from 'react-router-dom';

const PHONEPREFIX = [
  {
    id: '1',
    code: '+234',
    name: 'Nigeria',
    flag: nigerianFlag,
  },
  {
    id: '5',
    code: '+1',
    name: 'Canada',
    flag: nigerianFlag,
  },
  {
    id: '6',
    code: '+44',
    name: 'United Kingdom',
    flag: nigerianFlag,
  },
  {
    id: '7',
    code: '+1',
    name: 'United States of America',
    flag: nigerianFlag,
  },
];

const formSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your First Name'),
  email: Yup.string().email('Please enter a valid email').required('Please enter your Last Name'),
  // comments: Yup.string()
  //     .required('Please enter your Last Name'),
  phone_number: Yup.string()
    // .matches("/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[456789]\d{9}|(\d[ -]?){10}\d$/", 'Please enter a valid phone number')
    .required('Please enter a valid phone number'),
});

const Feedback = () => {
  const toast = useToast();
  const [phoneToUse, setPhoneToUse] = useState(PHONEPREFIX[0]);
  const [laoding, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      phone_number: '',
      name: '',
      email: '',
      comments: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: values => {
      setLoading(true);
      axios
        .post(`https://dev.matadortrust.com/v2/developers/developer_feedback`, {
          type: 'MyXellia',
          feedback: values.comments,
          name: values.name,
          email: values.email,
          phone: values.phone_number,
        })
        .then(res => {
          setSuccess(true);
        })
        .catch(err => {
          return toast({
            title: `${err?.response?.data?.resolve ?? 'Oops...'}`,
            description: `${err?.message ?? err?.response?.data?.message ?? err}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        })
        .finally(() => setLoading(false));
    },
    validationSchema: formSchema,
  });

  const handleSelectCountry = e => {
    const coutry = PHONEPREFIX.find(phone => phone.code === e.target.value);
    setPhoneToUse(coutry);
  };

  return (
    <FormWrapper success={success} type="feedback">
      <Flex
        borderRadius={'20px'}
        border={{base: 'none', md: '1px solid #E4E4E4'}}
        bg="rgba(255, 255, 255, 0.10)"
        color="#191919"
        px={{base: '20px', md: '40px'}}
        w={{base: '100%', md: '80%'}}
        mx="auto"
        h="fit-content"
        py={{base: '5px', md: '20px'}}
        direction={'column'}
        align={'stretch'}
        justify={'center'}
        gap={'28px'}
      >
        <Box>
          <Flex align={'center'} maxW={{base: 'full', md: '389px'}}>
            <Image
              src={angleLeft}
              onClick={() => navigate(-1)}
              mr="15px"
              h="20px"
              display={{base: 'block', md: 'none'}}
            />
            <Text
              fontWeight={500}
              fontSize={{base: '20px', md: '30px'}}
              fontFamily={{base: 'euclid-medium', md: 'euclid-semibold'}}
            >
              {' '}
              Give Feedback
            </Text>
          </Flex>
          <Text
            fontWeight={400}
            fontSize={'14px'}
            fontFamily={'euclid'}
            color="#606060"
            mt={{base: '15px', md: 'unset'}}
          >
            Help us improve our service by providing some feedback.
          </Text>
        </Box>
        <SimpleGrid
          column={2}
          columnGap={'10px'}
          rowGap={'16px'}
          pt={{base: '15px', md: 'unset'}}
          maxH={{base: 'unset', md: '50vh'}}
          overflowY={'scroll'}
        >
          <GridItem colSpan={2}>
            <Input
              mx="0"
              p="22px"
              _focus={{outline: 'none !important'}}
              _active={{outline: 'none !important'}}
              _focusVisible={{outline: 'none !important'}}
              value={formik.values.name}
              onChange={formik.handleChange('name')}
              border={
                formik.errors.name ? '1px solid red !important' : '1px solid #E4E4E4 !important'
              }
              bg="#fff"
              borderRadius="8px"
              placeholder="Full Name"
              _placeholder={{color: '#606060'}}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Input
              mx="0"
              p="22px"
              _focus={{outline: 'none !important'}}
              _active={{outline: 'none !important'}}
              _focusVisible={{outline: 'none !important'}}
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              border={
                formik.errors.email ? '1px solid red !important' : '1px solid #E4E4E4 !important'
              }
              bg="#fff"
              borderRadius="8px"
              placeholder="Email"
              _placeholder={{color: '#606060'}}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <InputGroup>
              <InputLeftAddon
                border={
                  formik.errors.phone_number
                    ? '1px solid red !important'
                    : '1px solid #E4E4E4 !important'
                }
                bg="#fff"
                borderRadius="8px"
                py="22px"
                px="5px"
                children={
                  <Select
                    border={'none'}
                    outline={'none'}
                    appearance={'none'}
                    _focus={{outline: 'none !important'}}
                    _active={{outline: 'none !important'}}
                    _focusVisible={{outline: 'none !important'}}
                    color="#191919"
                    value={phoneToUse.code}
                    onChange={handleSelectCountry}
                    className="phone_select"
                    px="4px"
                  >
                    {PHONEPREFIX.map(phone => (
                      <option style={{paddingLeft: 0}} value={phoneToUse.code} key={phone.id}>
                        {phone.code}
                      </option>
                    ))}
                  </Select>
                }
              />

              <Input
                mx="0"
                p="22px"
                _focus={{outline: 'none !important'}}
                _active={{outline: 'none !important'}}
                _focusVisible={{outline: 'none !important'}}
                value={formik.values.phone_number}
                onChange={formik.handleChange('phone_number')}
                border={
                  formik.errors.phone_number
                    ? '1px solid red !important'
                    : '1px solid #E4E4E4 !important'
                }
                bg="#fff"
                borderRadius="8px"
                placeholder="Phone number"
                _placeholder={{color: '#606060'}}
                type="tel"
              />
            </InputGroup>
          </GridItem>

          <GridItem colSpan={2}>
            <Textarea
              className="hide_scroll"
              h="150px"
              mx="0"
              py="10px"
              px="22px"
              _focus={{outline: 'none !important'}}
              _active={{outline: 'none !important'}}
              _focusVisible={{outline: 'none !important'}}
              value={formik.values.comments}
              onChange={formik.handleChange('comments')}
              border={
                formik.errors.comments ? '1px solid red !important' : '1px solid #E4E4E4 !important'
              }
              bg="#fff"
              borderRadius="8px"
              placeholder="Drop comment"
              _placeholder={{color: '#606060'}}
            />
          </GridItem>
        </SimpleGrid>

        <Button
          isLoading={laoding}
          color="#fff"
          h="54px"
          onClick={formik.handleSubmit}
          w="full"
          mx="auto"
          borderRadius={'12px'}
          border="none"
          bg="#4545FE"
          _hover={{bg: '#4545FE'}}
          _active={{bg: '#4545FE'}}
          _focus={{bg: '#4545FE'}}
          _disabled={{bg: '#4545FE'}}
        >
          Submit
        </Button>
      </Flex>
    </FormWrapper>
  );
};

export default Feedback;
