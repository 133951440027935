import React from 'react';
import {Flex, Text, HStack, Box, useDisclosure} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import {Button} from '../Buttons/index';
import bars from '../../assets/images/bars.svg';
import './Navbar.css';
import MobileDrawer from './MobileDrawer';

import './Navbar.css';
import {Image} from '@chakra-ui/react';

const Navbar = () => {
  const drawerDisclosure = useDisclosure();

  return (
    <Box mb={{base: '0', md: '92px'}}>
      <Flex
        position={'fixed'}
        w="full"
        top="0"
        right={0}
        bg="#fff"
        shadow={'sm'}
        borderBottom={'1px solid #E4E4E4'}
        display={{base: 'none', md: 'flex'}}
        zIndex={20}
        fontFamily={'euclid-medium'}
        h="94px"
        px="100px"
        justify={'space-between'}
        align={'center'}
      >
        <Flex align={'center'} gap={'70px'}>
          <Link to={'/'}>
            <Image w="164px" h="auto" src={logo} />
          </Link>
          <HStack align={'center'} spacing={'28px'}>
            <a
              href="https://veerge-support.myxellia.io/intro_to_veerge"
              target="_blank"
              rel="noreferrer"
            >
              <Text className="hover-underline-animation" bg="#fff">
                Solution
              </Text>
            </a>
            <a
              href="https://veerge-support.myxellia.io/blog/new_era"
              target="_blank"
              rel="noreferrer"
            >
              <Text className="hover-underline-animation" bg="#fff">
                Enterprise
              </Text>
            </a>
            <a href="https://tests-veerge.myxellia.io" target="_blank" rel="noreferrer">
              <Text className="hover-underline-animation" bg="#fff">
                Free Trial
              </Text>
            </a>
          </HStack>
        </Flex>
        <Flex align={'center'} gap="20px">
          <Link to={'/schedule'}>
            <Text className="hover-underline-animation" bg="#fff">
              Schedule Demo
            </Text>
          </Link>
          <Link to="/veerge">
            <Button bg="#fff" color="#4545FE" border="1px solid #4545FE" borderRadius="5px">
              Get Started
            </Button>
          </Link>
        </Flex>
      </Flex>

      <Flex
        position={'fixed'}
        top="0"
        right={0}
        bg="#fff"
        shadow={'sm'}
        borderBottom={'1px solid #E4E4E4'}
        w="full"
        px="18px"
        py="16px"
        zIndex={20}
        display={{base: 'flex', md: 'none'}}
        justify={'space-between'}
        align={'center'}
      >
        <Flex gap="24px" align={'center'}>
          <Box onClick={drawerDisclosure.onOpen}>
            <Image src={bars} />
          </Box>
          <Link to={'/'}>
            <Image w="94px" h="auto" src={logo} />
          </Link>
        </Flex>
        <Link to="/veerge">
          <Button
            h="34px"
            w="102px"
            bg="#fff"
            color="#4545FE"
            border="1px solid #4545FE"
            borderRadius="5px"
          >
            Get Started
          </Button>
        </Link>
      </Flex>

      <MobileDrawer drawerDisclosure={drawerDisclosure} />
    </Box>
  );
};

export default Navbar;
