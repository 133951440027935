import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import SuggestIdea from "./pages/Veerge/suggest-idea";
import Feedback from "./pages/Veerge/feedback";
import Refer from "./pages/Veerge/refer";
import Schedule from "./pages/Veerge/schedule";
import Navigation from "./pages/Veerge/navigation";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/veerge" element={<Navigation />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/suggest_idea" element={<SuggestIdea />} />
      <Route path="/refer" element={<Refer />} />
    </Routes>
  );
}

export default App;
