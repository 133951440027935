import {Box, Center, Divider, Flex, HStack, Image, Text} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {TypeAnimation} from 'react-type-animation';
import {Cursor} from 'react-simple-typewriter';
import backArrow from '../../assets/images/back-arrow.svg';
import {Success} from './successful';

const data = [
  'If experience isn’t your strategy, \n you’re doing it wrong',
  2000,
  "If experience isn’t your strategy,\n It's time to raise the bar",
  2000,
  'Build an end-to-end application without a single line of code',
  2000,
  'Build an end-to-end application that covers every part of Home-ownership',
  2000,
  'Build an end-to-end application that Automates due payment collection',
  2000,
  'Build an end-to-end application that can Fractionalise Real estate',
  2000,
  'Build an end-to-end application that collects payment in 160+ currencies and 70+ countries',
  2000,
  'Become a global business from day one!',
  2000,
  'Give customers what they need, when they need it!',
  2000,
  'Give customers what they need, how they need it!',
  2000,
  'Give customers what they need & evolve with the needs of the customer',
  2000,
  'Collaboration made easy with Veerge',
  2000,
  'Revolutionise your customer experince',
  2000,
  'Penetrate a new market with Fractional Real-estate',
  2000,
  'Strike a balance between the Day-to-day & planning of business strategies',
  2000,
  'The foundation of building an enduring & successful property development franchise',
  2000,
  'Bridge the Gap between what you have to offer & what your clients have grown to expect',
  2000,
  'Increase the longevity of the relationship you have with existing clientele',
  2000,
  'Your whole business on the go!',
  2000,
  'Keep your team connected with Loop',
  2000,
  'AI + Your business = Endless possibilities!',
  2000,
  'Sell in a whole new way!',
  2000,
  'Disrupting your way into a connected customer experience',
  2000,
  'A single view of your entire business that helps you to make decisions smarter & faster!',
  2000,
];

const FormWrapper = ({success = false, type = 'default', children}) => {
  const navigate = useNavigate();

  return (
    <Box w="100vw" h="100vh">
      <Flex w="100vw" h="100vh" align="stretch" position="absolute" bottom={0} left={0}>
        <Box display={{base: 'none', md: 'block'}} py="50px" w="55%" h="full" border={'none'}>
          <Flex
            w="85%"
            mx="auto"
            h="full"
            direction={'column'}
            justify={'space-between'}
            align={'stretch'}
          >
            <Flex
              onClick={() => navigate(-1)}
              cursor="pointer"
              direction={'row'}
              color="#3D3D3D"
              gap="12px"
              align="center"
            >
              <Center border="1px solid #3D3D3D" w="50px" h="50px" borderRadius={'full'}>
                <Image w="24px" h="auto" src={backArrow} />
              </Center>
              <Text fontWeight={500} fontSize={'24px'} fontFamily={'euclid-medium'}>
                Back
              </Text>
            </Flex>

            <Box zIndex={200}>
              <Flex direction={'column'} justify={'flex-start'} h="100px">
                <Text
                  color="#011B33"
                  fontSize="40px"
                  fontWeight="700 !important"
                  letterSpacing="-1.6px"
                  fontFamily={'euclid-semibold'}
                >
                  <TypeAnimation
                    style={{
                      leadingTrim: 'both',
                      textEdge: 'cap',
                      color: '#011B33',
                      fontSize: '40px',
                      fontWeight: '700 !important',
                      letterSpacing: '-1.6px',
                      fontFamily: 'euclid-semibold',
                    }}
                    sequence={data}
                    speed={200}
                    deletionSpeed={270}
                    repeat={Infinity}
                    cursor={false}
                  />
                  <Cursor
                    cursorStyle={
                      <span
                        style={{
                          background: '#011B33',
                          borderRadius: '40px',
                          fontSize: '25px',
                          marginLeft: '3px',
                          marginRight: '3px',
                          paddingLeft: '5px',
                        }}
                      >
                        &#9711;
                      </span>
                    }
                  />
                </Text>
              </Flex>
            </Box>

            <Box h="200px" />
          </Flex>
        </Box>

        <Flex
          // minH='100vh'
          h={'100vh'}
          maxH={'100vh'}
          w={{base: '100%', md: '45%'}}
          bg="#F5F5F5"
          direction={'column'}
          justify={{base: 'space-between', md: 'space-around'}}
          align={'stretch'}
          py={{base: '25px', md: '28px'}}
          border={'none'}
          overflowY={'scroll'}
        >
          <Box h="50px" display={{base: 'none', md: 'block'}} />
          {success ? <Success type={type} /> : children}
          <Flex mt="40px" direction={'column'} align={'center'} justify={'center'} gap="13px">
            <Text fontFamily={'syne_bold'} fontSize={'20px'} fontWeight={800}>
              veerge
            </Text>

            <HStack align="stretch" divider={<Divider orientation="vertical" />} spacing={'12px'}>
              <a target="_blank" rel="noreferrer" href={'https://veerge-support.myxellia.io/terms'}>
                <Text
                  cursor="pointer"
                  fontSize="14px"
                  fontWeight="400"
                  color="#3D3D3D"
                  opacity="0.7"
                  _hover={{textDecoration: 'underline'}}
                >
                  Terms of use
                </Text>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={'https://veerge-support.myxellia.io/privacy'}
              >
                <Text
                  cursor="pointer"
                  fontSize="14px"
                  fontWeight="400"
                  color="#3D3D3D"
                  opacity="0.7"
                  _hover={{textDecoration: 'underline'}}
                >
                  Privacy Policy
                </Text>
              </a>
              <a target="_blank" rel="noreferrer" href={'https://veerge-support.myxellia.io/blog'}>
                <Text
                  cursor="pointer"
                  fontSize="14px"
                  fontWeight="400"
                  color="#3D3D3D"
                  opacity="0.7"
                  _hover={{textDecoration: 'underline'}}
                >
                  Blog
                </Text>
              </a>
              <a target="_blank" rel="noreferrer" href={'https://veerge-support.myxellia.io/'}>
                <Text
                  cursor="pointer"
                  fontSize="14px"
                  fontWeight="400"
                  color="#3D3D3D"
                  opacity="0.7"
                  _hover={{textDecoration: 'underline'}}
                >
                  Help Center
                </Text>
              </a>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default FormWrapper;
